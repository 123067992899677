export const EMOJIS = [
    "ヽ( T-T)ノ",
    "o(╥﹏╥)o",
    "(ㄒoㄒ)",
    "(╯︵╰,)",
    "(oT-T)尸",
    "(⋟﹏⋞)",
    "〒_〒",
    "( ˃̣̣̥o˂̣̣̥ )",
    "(´;ω;)",
    "(T⌓T)",
    "(Ｔ▽Ｔ)",
    "Ó╭╮Ò",
    "(´。＿。｀)",
    "（┬┬＿┬┬）"
];